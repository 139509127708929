@import url(https://fonts.googleapis.com/css?family=Righteous);

body {
  margin: 0;
  background: rgba(13, 2, 33, 1);
  height: 100vh;
  font-weight: 700;
  font-family: Arial, Helvetica, sans-serif;
}

.root {
  overflow: hidden;
}
.bg-grid {
  position: fixed;
  height: 100vh;
  width: 100vw;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 20% 80%;
  grid-template-areas:
    "sun"
    "bg";
}
.sun-horizon {
  grid-area: bg;
  background: rgba(13, 2, 33, 1);
  bottom: 0;
  width: 100%;
  /* background-size: 4em 2em, 4em 2em; */
  height: 100%;
  /* perspective: 1000px; */
  /* margin-top: 20em; */
}
.sun {
  width: 200px;
  height: 200px;
  border-radius: 100%;
  background-color: yellow;
  background-image: linear-gradient(red, yellow), linear-gradient(black, white);
  clip-path: polygon(
    -50% 0px,
    150% -50%,
    150% 55%,
    -50% 55%,
    -50% 62%,
    150% 62%,
    150% 70%,
    0 70%,
    -50% 75%,
    150% 75%,
    150% 80%,
    0 80%,
    -50% 82%,
    150% 82%,
    150% 85%,
    0 85%,
    -50% 87%,
    150% 87%,
    150% 90%,
    0 90%,
    -50% 92%,
    150% 92%,
    150% 95%,
    0 95%,
    -50% 96%,
    150% 96%,
    150% 150%,
    0 150%
  );
  transition: all 3s;
}

.vert-bg {
  margin-top: 8em;
  grid-area: bg;
  width: 100%;
  background: linear-gradient(
    90deg,
    rgba(0, 0, 0, 0) 0px,
    rgba(247, 6, 207, 0.2) 0%,
    rgba(247, 6, 207, 0.2) 2px,
    rgba(0, 0, 0, 0) 0px
  );
  background-size: 4em 2em, 4em 2em;
  height: 100%;
  perspective: 1000px;
  transform: perspective(200px) rotateX(15deg) scale(4);
}
.bg {
  margin-top: 8em;
  grid-area: bg;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0px,
    rgba(247, 6, 207, 0.2) 0%,
    rgba(247, 6, 207, 0.2) 2px,
    rgba(0, 0, 0, 0) 0px
  );
  background-size: 4em 2em, 4em 2em;
  height: 100%;

  perspective: 1000px;
  transform: perspective(200px) rotateX(15deg) scale(4);
  animation: moving-grid 2s infinite linear;
}
@keyframes moving-grid {
  0% {
    transform: perspective(200px) rotateX(15deg) scale(4) translateY(0em);
  }
  100% {
    transform: perspective(200px) rotateX(15deg) scale(4) translateY(2em);
  }
}

.body {
  position: fixed;
  width: 100vw;
  height: 100vh;
  display: grid;
  grid-template-columns: 25% 50% 25%;
  grid-template-rows: 20% 65% auto;
  grid-template-areas:
    "title title title"
    "nav content ."
    "bottom bottom bottom";
}

.title-container {
  /* margin-top: 50px; */
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  grid-area: title;
}

.title {
  font-size: 90px;
  padding-left: 10px;
  padding-right: 10px;
  margin: 0;
  text-align: center;
  font-family: "Righteous", sans-serif;
  letter-spacing: 5px;

  color: #ffb7f3;
}

.alt-title {
  text-align: center;
  letter-spacing: 5px;
  font-size: 90px;
  color: #ffb7f3;
}

.nav-menu {
  padding-left: 20%;
  display: flex;
  justify-content: center;
  gap: 40px;
  align-items: flex-start;
  flex-direction: column;
  grid-row-start: 1;
  grid-row-end: 3;
  grid-column-start: 1;
}

.content {
  min-width: 560px;
  overflow: scroll;
  scroll-snap-type: y mandatory;
  scroll-behavior: smooth;
  grid-area: content;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.content::-webkit-scrollbar {
  display: none;
}

.bottom {
  grid-area: bottom;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

@media only screen and (max-width: 750px) {
  .title-container {
    flex-direction: column;
  }
  .title {
    font-size: 65px;
  }
  .sun-horizon {
    /* margin-top: 16em; */
    /* height: 0vh; */
    height: calc(100vh - 100px);
  }
  .sun {
    height: 140px;
    width: 140px;
  }
  .body {
    grid-template-rows: 20% 5% 65% auto;
    grid-template-columns: 100%;
    grid-template-areas:
      "title"
      "nav"
      "content"
      "bottom";
  }
  .nav-menu {
    padding-left: 0;
    align-items: center;
    grid-area: nav;
    gap: 0;
    flex-direction: row;
    justify-content: space-around;
  }
  .content {
    min-width: 0px;
    margin-left: 20px;
    margin-right: 20px;
  }
  .section {
    height: 70vh;
  }
}
