@import url(https://fonts.googleapis.com/css?family=Righteous);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
  margin: 0;
  background: rgba(13, 2, 33, 1);
  height: 100vh;
  font-weight: 700;
  font-family: Arial, Helvetica, sans-serif;
}

.root {
  overflow: hidden;
}
.bg-grid {
  position: fixed;
  height: 100vh;
  width: 100vw;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 20% 80%;
  grid-template-areas:
    "sun"
    "bg";
}
.sun-horizon {
  grid-area: bg;
  background: rgba(13, 2, 33, 1);
  bottom: 0;
  width: 100%;
  /* background-size: 4em 2em, 4em 2em; */
  height: 100%;
  /* perspective: 1000px; */
  /* margin-top: 20em; */
}
.sun {
  width: 200px;
  height: 200px;
  border-radius: 100%;
  background-color: yellow;
  background-image: linear-gradient(red, yellow), linear-gradient(black, white);
  -webkit-clip-path: polygon(
    -50% 0px,
    150% -50%,
    150% 55%,
    -50% 55%,
    -50% 62%,
    150% 62%,
    150% 70%,
    0 70%,
    -50% 75%,
    150% 75%,
    150% 80%,
    0 80%,
    -50% 82%,
    150% 82%,
    150% 85%,
    0 85%,
    -50% 87%,
    150% 87%,
    150% 90%,
    0 90%,
    -50% 92%,
    150% 92%,
    150% 95%,
    0 95%,
    -50% 96%,
    150% 96%,
    150% 150%,
    0 150%
  );
          clip-path: polygon(
    -50% 0px,
    150% -50%,
    150% 55%,
    -50% 55%,
    -50% 62%,
    150% 62%,
    150% 70%,
    0 70%,
    -50% 75%,
    150% 75%,
    150% 80%,
    0 80%,
    -50% 82%,
    150% 82%,
    150% 85%,
    0 85%,
    -50% 87%,
    150% 87%,
    150% 90%,
    0 90%,
    -50% 92%,
    150% 92%,
    150% 95%,
    0 95%,
    -50% 96%,
    150% 96%,
    150% 150%,
    0 150%
  );
  transition: all 3s;
}

.vert-bg {
  margin-top: 8em;
  grid-area: bg;
  width: 100%;
  background: linear-gradient(
    90deg,
    rgba(0, 0, 0, 0) 0px,
    rgba(247, 6, 207, 0.2) 0%,
    rgba(247, 6, 207, 0.2) 2px,
    rgba(0, 0, 0, 0) 0px
  );
  background-size: 4em 2em, 4em 2em;
  height: 100%;
  -webkit-perspective: 1000px;
          perspective: 1000px;
  -webkit-transform: perspective(200px) rotateX(15deg) scale(4);
          transform: perspective(200px) rotateX(15deg) scale(4);
}
.bg {
  margin-top: 8em;
  grid-area: bg;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0px,
    rgba(247, 6, 207, 0.2) 0%,
    rgba(247, 6, 207, 0.2) 2px,
    rgba(0, 0, 0, 0) 0px
  );
  background-size: 4em 2em, 4em 2em;
  height: 100%;

  -webkit-perspective: 1000px;

          perspective: 1000px;
  -webkit-transform: perspective(200px) rotateX(15deg) scale(4);
          transform: perspective(200px) rotateX(15deg) scale(4);
  -webkit-animation: moving-grid 2s infinite linear;
          animation: moving-grid 2s infinite linear;
}
@-webkit-keyframes moving-grid {
  0% {
    -webkit-transform: perspective(200px) rotateX(15deg) scale(4) translateY(0em);
            transform: perspective(200px) rotateX(15deg) scale(4) translateY(0em);
  }
  100% {
    -webkit-transform: perspective(200px) rotateX(15deg) scale(4) translateY(2em);
            transform: perspective(200px) rotateX(15deg) scale(4) translateY(2em);
  }
}
@keyframes moving-grid {
  0% {
    -webkit-transform: perspective(200px) rotateX(15deg) scale(4) translateY(0em);
            transform: perspective(200px) rotateX(15deg) scale(4) translateY(0em);
  }
  100% {
    -webkit-transform: perspective(200px) rotateX(15deg) scale(4) translateY(2em);
            transform: perspective(200px) rotateX(15deg) scale(4) translateY(2em);
  }
}

.body {
  position: fixed;
  width: 100vw;
  height: 100vh;
  display: grid;
  grid-template-columns: 25% 50% 25%;
  grid-template-rows: 20% 65% auto;
  grid-template-areas:
    "title title title"
    "nav content ."
    "bottom bottom bottom";
}

.title-container {
  /* margin-top: 50px; */
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  grid-area: title;
}

.title {
  font-size: 90px;
  padding-left: 10px;
  padding-right: 10px;
  margin: 0;
  text-align: center;
  font-family: "Righteous", sans-serif;
  letter-spacing: 5px;

  color: #ffb7f3;
}

.alt-title {
  text-align: center;
  letter-spacing: 5px;
  font-size: 90px;
  color: #ffb7f3;
}

.nav-menu {
  padding-left: 20%;
  display: flex;
  justify-content: center;
  grid-gap: 40px;
  gap: 40px;
  align-items: flex-start;
  flex-direction: column;
  grid-row-start: 1;
  grid-row-end: 3;
  grid-column-start: 1;
}

.content {
  min-width: 560px;
  overflow: scroll;
  scroll-snap-type: y mandatory;
  scroll-behavior: smooth;
  grid-area: content;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.content::-webkit-scrollbar {
  display: none;
}

.bottom {
  grid-area: bottom;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

@media only screen and (max-width: 750px) {
  .title-container {
    flex-direction: column;
  }
  .title {
    font-size: 65px;
  }
  .sun-horizon {
    /* margin-top: 16em; */
    /* height: 0vh; */
    height: calc(100vh - 100px);
  }
  .sun {
    height: 140px;
    width: 140px;
  }
  .body {
    grid-template-rows: 20% 5% 65% auto;
    grid-template-columns: 100%;
    grid-template-areas:
      "title"
      "nav"
      "content"
      "bottom";
  }
  .nav-menu {
    padding-left: 0;
    align-items: center;
    grid-area: nav;
    grid-gap: 0;
    gap: 0;
    flex-direction: row;
    justify-content: space-around;
  }
  .content {
    min-width: 0px;
    margin-left: 20px;
    margin-right: 20px;
  }
  .section {
    height: 70vh;
  }
}

.active-page {
  /* padding: 10px; */
  z-index: -1;
  position: absolute;
  padding: 10px;
  background: linear-gradient(
      180deg,
      rgba(255, 209, 119, 0.21) 0%,
      rgba(255, 228, 174, 0.124687) 51.04%,
      rgba(255, 255, 255, 0) 100%
    ),
    rgba(255, 119, 232, 0.12);
  border-radius: 10px;
  transition: top 0.2s, left 0.2s, width 0.2s;
}

.page {
  color: #ffb7f3;
}

a {
  text-decoration: none;
  position: relative;
  display: block;
  /* margin: 0 12px; */
  margin: 0;
  font-size: 1.2rem;
  text-transform: uppercase;
  transition: color 0.1s, background-color 0.1s;
  color: #ffb7f3;
}
a:hover {
  color: #ffb7f3;
}
a:focus,
a:active {
  color: #ffb7f3;
}

a::before {
  content: "";
  display: block;
  position: absolute;
  top: 100%;
  height: 3px;
  width: 100%;
  background-color: #ffb7f3;
  -webkit-transform-origin: center top;
  transform-origin: center top;
  -webkit-transform: scale(0, 1);
  transform: scale(0, 1);
  transition: color 0.1s, -webkit-transform 0.2s ease-out;
  transition: color 0.1s, transform 0.2s ease-out;
  transition: color 0.1s, transform 0.2s ease-out, -webkit-transform 0.2s ease-out;
  transition: color 0.1s, transform 0.2s ease-out,
    -webkit-transform 0.2s ease-out;
}

a:hover::before {
  -webkit-transform-origin: center top;
  transform-origin: center top;
  -webkit-transform: scale(1, 1);
  transform: scale(1, 1);
}

@media only screen and (max-width: 750px) {
  a {
    font-size: 16px;
  }
  a::before {
    content: none;
  }
}

.section {
  height: 65vh;
  overflow: hidden;
  scroll-snap-align: center;
  scroll-snap-type: y mandatory;
  scroll-snap-stop: always;
  display: flex;
  flex-direction: column;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.section::-webkit-scrollbar {
  display: none;
}

.text {
  color: white;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 32px;
  font-weight: lighter;
  text-align: center;
}
.portfolio-grid {
  overflow: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  height: 100%;
}

.portfolio-grid::-webkit-scrollbar {
  display: none;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.portfolio-item {
  background: linear-gradient(
      180deg,
      rgba(255, 209, 119, 0.21) 0%,
      rgba(255, 227, 172, 0.12817) 0.01%,
      rgba(255, 228, 174, 0.124687) 51.04%,
      rgba(255, 255, 255, 0) 100%
    ),
    rgba(255, 119, 232, 0.12);
  border-radius: 15px;
  overflow: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.portfolio-item::-webkit-scrollbar {
  display: none;
}

.portfolio-nav {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}

.portfolio-tabs {
  color: #ffb7f3;
  flex-grow: 1;
}
.portfolio-title {
  margin: 0;
  color: #fff;
  text-align: center;
  margin-right: 5px;
}
.portfolio-description {
  margin: 0;
  margin-top: 3px;
  color: #fff;
  font-weight: lighter;
  font-size: 18px;
}
.portfolio-subtitle {
  font-style: italic;
  font-weight: lighter;
  margin: 0;
  color: #fff;
  text-decoration: none;
  text-align: center;
}
.portfolio-preview-wrapper {
  display: flex;
  justify-content: space-around;
  padding: 10px;
  align-content: space-around;
  align-items: center;
}
.portfolio-top-wrapper {
  display: flex;
  justify-content: space-around;
  padding-left: 25px;
  padding-right: 25px;

  align-content: space-around;
  align-items: flex-start;
}

@media only screen and (max-width: 750px) {
  .section {
    height: 65vh;
  }
  .portfolio-tabs {
    display: none !important;
  }
}

.bottom-bar {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  color: #ffb7f3;
  flex-grow: 99;
}

.icon {
  fill: #ffb7f3;
  width: 36px;
  height: 36px;
}

.material-icon {
  padding-left: 10px;
  padding-right: 10px;
  color: #ffb7f3;
}

.button-text {
  color: #ffb7f3;
  font-weight: 500;
  font-size: 18px;
  margin: 0px;
}

@media only screen and (max-width: 750px) {
  .icon {
    height: 24px;
    width: 24px;
  }
  .button-text {
    font-size: 14px;
  }
}

