.active-page {
  /* padding: 10px; */
  z-index: -1;
  position: absolute;
  padding: 10px;
  background: linear-gradient(
      180deg,
      rgba(255, 209, 119, 0.21) 0%,
      rgba(255, 228, 174, 0.124687) 51.04%,
      rgba(255, 255, 255, 0) 100%
    ),
    rgba(255, 119, 232, 0.12);
  border-radius: 10px;
  transition: top 0.2s, left 0.2s, width 0.2s;
}

.page {
  color: #ffb7f3;
}

a {
  text-decoration: none;
  position: relative;
  display: block;
  /* margin: 0 12px; */
  margin: 0;
  font-size: 1.2rem;
  text-transform: uppercase;
  transition: color 0.1s, background-color 0.1s;
  color: #ffb7f3;
}
a:hover {
  color: #ffb7f3;
}
a:focus,
a:active {
  color: #ffb7f3;
}

a::before {
  content: "";
  display: block;
  position: absolute;
  top: 100%;
  height: 3px;
  width: 100%;
  background-color: #ffb7f3;
  -webkit-transform-origin: center top;
  transform-origin: center top;
  -webkit-transform: scale(0, 1);
  transform: scale(0, 1);
  transition: color 0.1s, -webkit-transform 0.2s ease-out;
  transition: color 0.1s, transform 0.2s ease-out;
  transition: color 0.1s, transform 0.2s ease-out,
    -webkit-transform 0.2s ease-out;
}

a:hover::before {
  -webkit-transform-origin: center top;
  transform-origin: center top;
  -webkit-transform: scale(1, 1);
  transform: scale(1, 1);
}

@media only screen and (max-width: 750px) {
  a {
    font-size: 16px;
  }
  a::before {
    content: none;
  }
}
