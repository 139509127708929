.section {
  height: 65vh;
  overflow: hidden;
  scroll-snap-align: center;
  scroll-snap-type: y mandatory;
  scroll-snap-stop: always;
  display: flex;
  flex-direction: column;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.section::-webkit-scrollbar {
  display: none;
}

.text {
  color: white;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 32px;
  font-weight: lighter;
  text-align: center;
}
.portfolio-grid {
  overflow: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  height: 100%;
}

.portfolio-grid::-webkit-scrollbar {
  display: none;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.portfolio-item {
  background: linear-gradient(
      180deg,
      rgba(255, 209, 119, 0.21) 0%,
      rgba(255, 227, 172, 0.12817) 0.01%,
      rgba(255, 228, 174, 0.124687) 51.04%,
      rgba(255, 255, 255, 0) 100%
    ),
    rgba(255, 119, 232, 0.12);
  border-radius: 15px;
  overflow: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.portfolio-item::-webkit-scrollbar {
  display: none;
}

.portfolio-nav {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}

.portfolio-tabs {
  color: #ffb7f3;
  flex-grow: 1;
}
.portfolio-title {
  margin: 0;
  color: #fff;
  text-align: center;
  margin-right: 5px;
}
.portfolio-description {
  margin: 0;
  margin-top: 3px;
  color: #fff;
  font-weight: lighter;
  font-size: 18px;
}
.portfolio-subtitle {
  font-style: italic;
  font-weight: lighter;
  margin: 0;
  color: #fff;
  text-decoration: none;
  text-align: center;
}
.portfolio-preview-wrapper {
  display: flex;
  justify-content: space-around;
  padding: 10px;
  align-content: space-around;
  align-items: center;
}
.portfolio-top-wrapper {
  display: flex;
  justify-content: space-around;
  padding-left: 25px;
  padding-right: 25px;

  align-content: space-around;
  align-items: flex-start;
}

@media only screen and (max-width: 750px) {
  .section {
    height: 65vh;
  }
  .portfolio-tabs {
    display: none !important;
  }
}
